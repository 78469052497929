<template>
  <base-layout>
    <div class="card session-head">
      <div class="item-header-gradient"></div>
      <div class="" :style="'background-color:'+session.color">
        <div class="py-2" v-if="sessiontype" :style="'color:'+sessiontype.font_color">
          <strong class="title ms-3 me-2 d-block"><span v-if="session.code"> {{ session.code }}  - </span> <span
              v-html="session.title"></span></strong>
          <div class="time ms-3" v-if="sessiontype" :style="'color:'+sessiontype.font_color">
            {{ moment(session.start_time).format('dddd HH:mm') }} -
            {{ moment(session.end_time).format('HH:mm') }} {{ timezoneString }}<br>
          </div>
        </div>

        <div class="session-corner-annotation" v-if="session.is_ted">
          <div class="annotation-text"> TED</div>
        </div>
      </div>
    </div>
    <ion-content>
      <div class="container-fluid p-0 p-md-3" v-if="sessions">
        <div class="px-2" style="border:none" v-if="sessions && sessions.length > 0"
        >
          <h3 class=" mt-2 mb-0">Sessions in this collection</h3>
        </div>
        <div
            class="row row-cols-1 row-cols-md-3  row-cols-lg-4 row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start">

          <template v-for="session in sessions" :key="session.id">
            <session-item :session="session" v-if="session"></session-item>
          </template>

        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapState} from 'vuex';
import {gridOutline, chevronForward, chevronBack} from 'ionicons/icons';
import moment from 'moment';
import SessionItem from "@/components/SessionItem"
//moment.locale("de");


export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      session: {},
      sessiontype: null
    }
  },

  setup() {
    return {
      gridOutline,
      chevronForward,
      chevronBack
    }
  },
  components: {
    IonContent,
    'session-item': SessionItem
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getSessionListInCollection', 'getSingleSession', 'getSessionType']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    async fetchSessions(collection_id) {
      this.sessions = (await this.getSessionListInCollection(collection_id)).sort((a, b) => {
        return a.order - b.order;
      })

      this.sessions = this.groupList(this.sessions, 'start_time');
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHead: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },


    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToGrid() {
      this.$router.push('/app/gridList');
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {
    this.session = await this.getSingleSession(this.$route.params.collection_id);
    this.sessiontype = await this.getSessionType(this.session.id)

    this.fetchSessions(this.$route.params.collection_id);
    this.moment = moment;
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions(this.$route.params.collection_id)
      }
    },
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'SessionCollectionList') {
          this.moment = moment;
          this.session = await this.getSingleSession(this.$route.params.collection_id);
          this.sessiontype = await this.getSessionType(this.session.id)
          this.fetchSessions(this.$route.params.collection_id);
        }
      }
    },
  },
});
</script>
<style lang="scss">


.day-selector {
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover {
  cursor: pointer;
}

.day-selector-item {
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.day-number {
  width: 100%;
  color: white;

}

.day-number-selected {
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.large-selected {
  font-weight: 900;
}


</style>
